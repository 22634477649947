import * as React from 'react'
import { ProductsHttp } from '../../services/products.service'
import { ProductAttribute } from '../../types/product'
import { Checkbox, CheckboxProps, CollapseProps } from 'antd'

export interface IDynamicPanelProps {
  itemKey: number
}
const productsHttp = new ProductsHttp()
export default function DynamicPanel(props: IDynamicPanelProps) {
  const { itemKey } = props
  const [attributeTerms, setAttributeTerms] = React.useState<
    ProductAttribute[]
  >([])

  React.useEffect(() => {
    const getDetailAttributes = async () => {
      try {
        const response = await productsHttp.getDetailAttributes(itemKey)
        setAttributeTerms(response.data)
      } catch (error) {
        console.log('error', error)
      }
    }

    getDetailAttributes()
  }, [itemKey])

  const onChange: CheckboxProps['onChange'] = (e) => {
    console.log(`checked = ${e.target.checked}`)
  }

  return (
    <div className="flex flex-col">
      {attributeTerms &&
        attributeTerms.length > 0 &&
        attributeTerms.map((item) => (
          <Checkbox key={item.id} onChange={onChange}>
            {item.name}
          </Checkbox>
        ))}
    </div>
  )
}
