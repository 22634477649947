import React, { useState, FormEvent, useCallback, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { GlobalHttp } from '../../services/global.service'
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  SwapRightOutlined,
} from '@ant-design/icons'
import { Checkbox, CheckboxProps, Input, message, Spin } from 'antd'
import './style/login.css'
interface LocationState {
  from: {
    pathname: string
  }
}

interface LoginResponse {
  token: string
}
const globalHttp = new GlobalHttp()
const Login: React.FC = () => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const navigate = useNavigate()
  const location = useLocation()
  const [isRemember, setIsRemember] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    const isRemember = localStorage.getItem('isRemember')
    if (isRemember) {
      setIsRemember(JSON.parse(isRemember))
      setUsername(localStorage.getItem('username') || '')
    }
  }, [])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const formData = {
        username,
        password,
      }
      if (formData.username === '' || formData.password === '') {
        return message.error('Vui lòng nhập đầy đủ thông tin đăng nhập!')
      }
      const response = await globalHttp.login(formData)
      if (response.status === 200 && response.data) {
        const { jwt_token } = response.data
        localStorage.setItem('token', jwt_token)
        const origin = (location.state as LocationState)?.from?.pathname || '/'
        navigate(origin)
      } else {
        message.error(`Đăng nhập thất bại. ${response.data.error_description}!`)
      }
    } catch (error) {
      console.error('Lỗi:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { checked } = e.target
    setIsRemember(checked)
    if (checked) {
      localStorage.setItem('isRemember', JSON.stringify(checked))
      localStorage.setItem('username', username)
    } else {
      localStorage.removeItem('isRemember')
      localStorage.removeItem('username')
    }
  }
  return (
    <>
      <div className="wrapper">
        <div className="logo">
          <img src="./logo.png" alt="" />
        </div>
        <div className="text-center mt-4 name">Goldwin Store</div>
        <form onSubmit={handleSubmit}>
          <div className="form-field d-flex align-items-center mt-4">
            <span className="far fa-user" />
            <input
              type="text"
              name="userName"
              id="userName"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-field d-flex align-items-center">
            <span className="fas fa-key" />
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              id="pwd"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="password-toggle"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </button>
          </div>
          <div className="checkbox-wrapper-4">
            <input
              className="inp-cbx"
              id="rememberMe"
              type="checkbox"
              onChange={onChange}
              checked={isRemember}
              disabled={username === ''}
            />
            <label className="cbx" htmlFor="rememberMe">
              <span>
                <svg width="12px" height="10px">
                  <use xlinkHref="#check-4"></use>
                </svg>
              </span>
              <span>Remember me</span>
            </label>
          </div>
          <button className="btn mt-3">Login</button>
        </form>
        <div className="text-center fs-6 mt-3">
          <a
            rel="noreferrer"
            href="https://goldwinstore.com/wp-admin"
            className="font-semibold text-goldwin-color-100"
            target="_blank"
          >
            Đến trang Admin Wordpress
          </a>
        </div>
      </div>
      <Spin spinning={isLoading} fullscreen />
    </>
  )
}

export default Login
