import React, { useContext, useEffect, useLayoutEffect } from 'react'
import './App.css'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import Home from './page/Home'
import ProtectedRoute from './components/ProtectedRoute'
import Login from './page/Login'
import { GlobalHttp } from './services/global.service'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
