import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios'

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders
}

export class ResponseError extends Error {
  public response: Response
  constructor(response: Response) {
    super(response.statusText)
    this.response = response
  }
}

function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) return null
  return response.json()
}

async function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) return response
  const error = new ResponseError(response)
  error.response = response
  throw error
}

export async function request(url: string, options?: any): Promise<any> {
  const fetchResponse = await fetch(url, options || {})
  const response = await checkStatus(fetchResponse as unknown as Response)
  return parseJSON(response)
}

export class HttpRequest {
  axiosInstance: AxiosInstance
  constructor(APIEndpoint: string) {
    this.axiosInstance = axios.create({
      baseURL: APIEndpoint,
      auth: {
        username: 'ck_90a48038ecfd71e757effa8f98417830314432ea',
        password: 'cs_47d616bff5466c038e306a9b10f879a882946fa4',
      },
      timeout: 30 * 1000, // timeout 30s
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        mode: 'no-cors',
      },
    })
    this.axiosInstance.interceptors.request.use(
      async (config: AdaptAxiosRequestConfig) => {
        return config
      },
      (error: AxiosError) => {
        return Promise.reject(error)
      },
    )

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      (error: AxiosError) => {
        return error.response
      },
    )
  }
}
