import { AxiosInstance, AxiosResponse } from 'axios'
import { API, APP_URL } from '../constants/const'
import { HttpRequest } from '../config/api'
import { LoginData, LoginResponseData } from '../types'

export class GlobalHttp {
  request: AxiosInstance
  constructor(endPoint = APP_URL.END_POINT) {
    this.request = new HttpRequest(endPoint).axiosInstance
  }

  public getGlobal(): Promise<any> {
    return this.request.get(API.GLOBAL)
  }

  public login(data: LoginData): Promise<AxiosResponse<LoginResponseData>> {
    return this.request.post<LoginResponseData>(API.LOGIN, data)
  }
  public checkTokenValid(): Promise<any> {
    return this.request.get(API.VALID_TOKEN)
  }
}
