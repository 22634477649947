import {
  EditOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Layout, Menu, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import Products from '../Products'
import Attributes from '../Attributes'
import { useNotification } from '../../hooks/useNotification'

const { Header, Sider, Content } = Layout

const gridStyle: React.CSSProperties = {
  width: '25%',
  textAlign: 'center',
}

export default function Home() {
  const [collapsed, setCollapsed] = useState(false)
  const { openNofificationInfo, contextHolder } = useNotification()
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken()

  const [active, setActive] = useState<string>('1')

  const onClickMenu = ({ key }: { key: string }) => {
    setActive(key)
  }

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          onClick={onClickMenu}
          items={[
            {
              key: '1',
              icon: <UserOutlined />,
              label: 'Products',
            },
            {
              key: '2',
              icon: <EditOutlined />,
              label: 'Products Attributes',
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            margin: '16px 8px',
            padding: 24,
            height: '100%',
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            overflowY: 'auto',
          }}
        >
          {active === '1' && <Products />}
          {active === '2' && <Attributes />}
        </Content>
      </Layout>
      {contextHolder}
    </Layout>
  )
}
