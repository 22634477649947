import {
  Button,
  Flex,
  message,
  Popconfirm,
  Table,
  TableColumnsType,
  Tag,
  Typography,
} from 'antd'

import React from 'react'
import { QuestionOutlined } from '@ant-design/icons'
import { ProductsHttp } from '../../services/products.service'
import { useStore } from '../../store'
import { AppHelper } from '../../utils/helper'
import { Product } from '../../types/product'
import { selectorProducts } from '../../store/selectors/product.selector'
import EditProduct from './EditProduct'

const { Text } = Typography

type TypeAction = 'show' | 'hidden' | 'delete' | 'edit'
const productsHttp = new ProductsHttp()
const ProductTable: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([])
  const [loading, setLoading] = React.useState(false)
  const { products, setProducts } = useStore(selectorProducts)
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(
    null,
  )
  const [isOpenEdit, setIsOpenEdit] = React.useState(false)

  React.useEffect(() => {
    fetchProducts()
  }, [])

  const fetchProducts = async () => {
    setLoading(true)
    try {
      const response = await productsHttp.getProducts()
      const productsWithDetails = await Promise.all(
        response.data.map(async (product) => {
          if (product.type === 'variable') {
            const variantsResponse = await productsHttp.productVariation(
              product.id.toString(),
            )
            const processedVariations = processVariations(variantsResponse.data)
            return { ...product, processedVariations }
          }
          return product
        }),
      )
      setProducts(productsWithDetails)
    } catch (error) {
      console.log('FAILED')
    } finally {
      setLoading(false)
    }
  }

  const processVariations = (variations: any[]) => {
    // Lọc ra các biến thể có giá hợp lệ
    const validVariations = variations.filter(
      (v) => v.regular_price && v.regular_price !== '',
    )

    if (validVariations.length === 0) {
      return {
        priceDisplay: '0',
        priceDisplaySales: null,
        priceDetails: [],
      }
    }
    // Sắp xếp giá từ thấp đến cao theo giá gốc
    const sortedVariations = validVariations.sort(
      (a, b) => parseFloat(a.regular_price) - parseFloat(b.regular_price),
    )
    // Xử lý hiển thị giá gốc
    let priceDisplay
    if (sortedVariations.length === 1) {
      priceDisplay = sortedVariations[0].regular_price
    } else {
      priceDisplay = sortedVariations[0].regular_price
    }
    // Xử lý hiển thị giá khuyến mãi
    let priceDisplaySales = null
    const saleVariations = sortedVariations.filter(
      (v) => v.sale_price && v.sale_price !== '',
    )
    if (saleVariations.length > 0) {
      saleVariations.sort(
        (a, b) => parseFloat(a.sale_price) - parseFloat(b.sale_price),
      )
      if (saleVariations.length === 1) {
        priceDisplaySales = saleVariations[0].sale_price
      } else {
        priceDisplaySales = saleVariations[0].sale_price
      }
    }
    // Tạo chi tiết giá
    const priceDetails = sortedVariations.map((variation) => ({
      regular_price: parseFloat(variation.regular_price).toLocaleString(),
      sale_price: variation.sale_price
        ? parseFloat(variation.sale_price).toLocaleString()
        : null,
      attributes: variation.attributes
        .map((attr: any) => attr.option)
        .join(', '),
    }))

    return { priceDisplay, priceDisplaySales, priceDetails }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const columns: TableColumnsType<Product> = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'images',
      width: 400,
      render: (value, record) => {
        if (value.length === 0)
          return (
            <div className="flex items-center">
              <img
                src="./photo.png"
                alt="Not found"
                className="w-12 h-12 object-cover rounded-2xl mr-4"
              />
              <p>{record.name}</p>
            </div>
          )
        return (
          <div className="flex items-center">
            <img
              src={value[0].src}
              alt={value[0].name}
              className="w-12 h-12 object-cover rounded-full mr-4"
            />
            <p>{record.name}</p>
          </div>
        )
      },
    },

    {
      title: 'Kho',
      dataIndex: 'stock_status',
      render(value, record, index) {
        return value === 'instock' ? (
          <Tag color="green">Còn hàng</Tag>
        ) : (
          <Tag color="red">Hết hàng</Tag>
        )
      },
    },
    {
      title: 'Giá',
      dataIndex: 'processedVariations',
      width: 200,
      render(value, record) {
        if (value && value.priceDisplay && value.priceDisplaySales) {
          return (
            <div className="flex flex-col">
              <Text delete>
                {AppHelper.fomartCurrencyVND(+value.priceDisplay)}
              </Text>
              <Text underline type="success">
                {AppHelper.fomartCurrencyVND(+value.priceDisplaySales)}
              </Text>
            </div>
          )
        }
        if (value && value.priceDisplay && value.priceDisplaySales === null) {
          return <Text>{AppHelper.fomartCurrencyVND(+value.priceDisplay)}</Text>
        }
        return <Text>{AppHelper.fomartCurrencyVND(0)}</Text>
      },
    },
    {
      title: 'Danh mục',
      dataIndex: 'categories',
      render(value, record, index) {
        const categoryNames = record.categories.map((category) => category.name)
        return (
          <p className="text-blue-500 font-semibold">
            {categoryNames.join(', ')}
          </p>
        )
      },
    },

    {
      title: 'Ngày cập nhật',
      dataIndex: 'date_modified',
      render: (value) => AppHelper.formatDate(value, 'DD/MM/YYYY,'),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value) => {
        if (value === 'publish') {
          return <Tag color="green">Đã xuất bản</Tag>
        } else {
          return <Tag color="red">Chưa xuất bản</Tag>
        }
      },
    },
    {
      title: 'Action',
      render: (record) => {
        return (
          <Flex gap={2}>
            <button
              className="text-xs text-blue-500"
              onClick={() => handleAction('edit', record)}
            >
              Edit
            </button>{' '}
            |
            {record.status === 'publish' ? (
              <button
                className="text-xs text-[#9254de]"
                onClick={() => handleAction('hidden', record)}
              >
                Hidden
              </button>
            ) : (
              <button
                className="text-xs text-[#73d13d]"
                onClick={() => handleAction('show', record)}
              >
                Show
              </button>
            )}
            |
            <Popconfirm
              title="Xác nhận xóa sản phẩm này?"
              description="Bạn có chắc chắn muốn xóa sản phẩm này?"
              onConfirm={() => handleAction('delete', record)}
              okText="Xóa"
              okType="danger"
              icon={<QuestionOutlined />}
              placement="bottomLeft"
            >
              <button className="text-xs text-red-500">Del</button>{' '}
            </Popconfirm>
          </Flex>
        )
      },
    },
  ]

  const onConfirmDelete = async (id: number) => {
    const response = await productsHttp.deleteProduct(id.toString())
    if (response && response.status === 200) {
      const newProducts = products.filter((product) => product.id !== id)
      setProducts(newProducts)
      message.success(`Xóa sản phẩm thành công!`)
      return
    }
    message.error(`Xóa sản phẩm thất bại! ${response.data?.message}`)
  }
  const onConfirmDelMulti = () => {
    setLoading(true)
    deleteProducts(selectedRowKeys).then((result) => {
      if (result.failed > 0) {
        message.error(`Xóa sản phẩm thất bại! ${result}`)
        return
      }
      message.success(`Xóa sản phẩm thành công!`)
      setSelectedRowKeys([])
      setLoading(false)
    })
  }

  const deleteProduct = async (productId: React.Key) => {
    try {
      const response = await productsHttp.deleteProduct(productId.toString())
      return response.status === 200
    } catch (error) {
      message.error(`Xóa sản phẩm thất bại! ${error}`)
    }
  }

  const deleteProducts = async (productIds: React.Key[]) => {
    const deletePromises = productIds.map((id) => deleteProduct(id))
    const results = await Promise.all(deletePromises)

    const successfulDeletes = results.filter((result) => result === true)
    const failedDeletes = results.filter((result) => result === false)

    const updatedListProduct = products.filter(
      (product) =>
        !productIds.includes(product.id) ||
        !successfulDeletes[productIds.indexOf(product.id)],
    )
    setProducts(updatedListProduct)
    return {
      success: successfulDeletes.length,
      failed: failedDeletes.length,
    }
  }

  const updateStatusProduct = async (status: string, value: any) => {
    setLoading(true)
    const data = { ...value, status: status }
    try {
      const response = await productsHttp.updateProduct(
        value.id.toString(),
        data,
      )
      if (response.status === 200) {
        const newProducts = products.map((product) => {
          if (product.id === value.id) {
            return { ...product, status }
          }
          return product
        })
        setProducts(newProducts)
        message.success(`Cập nhật trạng thái sản phẩm thành công!`)
      }
    } catch (error) {
      message.error(`Cập nhật trạng thái sản phẩm thất bại! ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const handleAction = (action: TypeAction, value: Product) => {
    switch (action) {
      case 'show':
        updateStatusProduct('publish', value)
        break
      case 'hidden':
        updateStatusProduct('private', value)
        break
      case 'delete':
        onConfirmDelete(value.id)
        break
      case 'edit':
        setSelectedProduct(value)
        setIsOpenEdit(true)
        break
      default:
        break
    }
  }

  const hanldeStatus = (action: 'show' | 'hidden', value: Product) => {
    if (action === 'show') {
      updateStatusProduct('publish', value)
    }
    if (action === 'hidden') {
      updateStatusProduct('private', value)
    }
  }

  return (
    <>
      <Popconfirm
        title="Xác nhận xóa sản phẩm này?"
        description="Bạn có chắc chắn muốn xóa các sản phẩm này?"
        onConfirm={() => onConfirmDelMulti()}
        okText="Xóa"
        okType="danger"
        icon={<QuestionOutlined />}
        placement="bottomLeft"
      >
        <Button
          danger
          loading={loading}
          className="mb-2"
          disabled={selectedRowKeys.length === 0}
        >
          Remove ({selectedRowKeys.length})
        </Button>
      </Popconfirm>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={products}
        rowKey={(record) => record.id}
        pagination={{ pageSize: 20 }}
        scroll={{ y: 'calc(100vh - 350px)', x: 'auto' }}
      />
      {selectedProduct ? (
        <EditProduct
          open={isOpenEdit}
          onClose={() => setIsOpenEdit(false)}
          item={selectedProduct}
        />
      ) : null}
    </>
  )
}
export default ProductTable
