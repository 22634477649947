import { AxiosInstance, AxiosResponse } from 'axios'
import { API, APP_URL } from '../constants/const'
import { HttpRequest } from '../config/api'
import { ResponseProducts } from '../types/response'
import {
  CreateProduct,
  Product,
  ProductAttribute,
  ProductVariation,
} from '../types/product'

export class ProductsHttp {
  request: AxiosInstance
  constructor(endPoint = APP_URL.END_POINT) {
    this.request = new HttpRequest(endPoint).axiosInstance
  }

  public getProducts(): Promise<AxiosResponse<Product[]>> {
    return this.request.get(
      `${API.PRODUCTS.GET_PRODUCTS}?per_page=100&type=variable`,
    )
  }

  public getCategory(): Promise<AxiosResponse> {
    return this.request.get(`${API.PRODUCTS.GET_CATEGORY}`)
  }

  public createProduct(data: CreateProduct): Promise<AxiosResponse<any>> {
    return this.request.post(API.PRODUCTS.CREATE_PRODUCT, data)
  }

  public getAttributes(): Promise<AxiosResponse<ProductAttribute[]>> {
    return this.request.get(API.PRODUCTS.ATTRIBUTES)
  }
  public getDetailAttributes(
    id: number,
  ): Promise<AxiosResponse<ProductAttribute[]>> {
    return this.request.get(`${API.PRODUCTS.DETAIL_ATTRIBUTES}/${id}/terms`)
  }
  public productVariation(
    productId: string,
  ): Promise<AxiosResponse<ProductVariation[]>> {
    return this.request.get(
      `${API.PRODUCTS.PRODUCT_VARIATION.replace(':id', productId)}`,
    )
  }
  public generateVariationProduct(
    id: string,
    variation: any,
  ): Promise<AxiosResponse<any>> {
    return this.request.post(
      `${API.PRODUCTS.GENERATE_VARIATION_PRODUCT.replace(':id', id)}`,
      variation,
    )
  }
  public deleteProduct(id: string): Promise<AxiosResponse<any>> {
    return this.request.delete(
      `${API.PRODUCTS.DELETE_PRODUCT.replace(':id', id)}`,
    )
  }
  public updateProduct( id: string, data: CreateProduct): Promise<AxiosResponse<any>> {
    return this.request.put(
      `${API.PRODUCTS.UPDATE_PRODUCT.replace(':id', id)}`,
      data,
    )
  }
}
