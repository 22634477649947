import { CREATE, DELETE } from 'react-admin'
import { ICategories, IPrice, ITypeProduct, TypePrice } from '../types/product'

export const APP_URL = {
  END_POINT: 'https://goldwinstore.com',
}

export const API = {
  PRODUCTS: {
    GET_PRODUCTS: '/wp-json/wc/v3/products',
    GET_CATEGORY: '/wp-json/wc/v3/products/categories',
    CREATE_PRODUCT: '/wp-json/wc/v3/products',
    ATTRIBUTES: '/wp-json/wc/v3/products/attributes',
    DETAIL_ATTRIBUTES: '/wp-json/wc/v3/products/attributes',
    PRODUCT_VARIATION: '/wp-json/wc/v3/products/:id/variations',
    GENERATE_VARIATION_PRODUCT: '/wp-json/wc/v3/products/:id/variations',
    DELETE_PRODUCT: '/wp-json/wc/v3/products/:id',
    UPDATE_PRODUCT: '/wp-json/wc/v3/products/:id',
  },
  GLOBAL: '/wp-json/wc/v3/products',
  MEDIA: '/wp-json/wp/v2/media',
  LOGIN: '/wp-json/api/v1/token',
  VALID_TOKEN: '/wp-json/api/v1/token-validate',
}

export const Categories: ICategories[] = [
  { id: 'nike', name: 'Nike' },
  { id: 'adidas', name: 'Adidas' },
  { id: 'asics', name: 'Asics' },
  { id: 'on-running', name: 'On Running' },
]

export enum FORMAT {
  YYYY_MM_DD = 'YYYY-MM-DD',
  HH_MM = 'HH:mm',
  DATE_FULL = 'DD-MM-YYYY, HH:mm A',
  DD_MM_YYYY = 'DD/MM/YYYY',
  DD_MM_YYYY_HH_MM = 'DD/MM/YYYY, HH:mm',
  DDDD = 'dddd, ',
}

export const Price: TypePrice = {
  low: 1300000,
  medium: 1450000,
  high: 1600000,
  premium: 2250000,
}

export const ListTypeProducts: ITypeProduct[] = [
  {
    id: 1,
    price: Price.low,
    type: 'low',
    label: 'Phổ thông',
    isChecked: false,
  },
  {
    id: 2,
    price: Price.medium,
    type: 'medium',
    label: 'Trung học',
    isChecked: false,
  },
  {
    id: 3,
    price: Price.high,
    type: 'high',
    label: 'Cao cấp',
    isChecked: false,
  },
  {
    id: 4,
    price: Price.premium,
    type: 'premium',
    label: 'Siêu cấp',
    isChecked: false,
  },
]

export const PriceList: IPrice[] = [
  { id: 1, price: 50000, isChecked: false },
  { id: 2, price: 100000, isChecked: false },
  { id: 3, price: 200000, isChecked: false },
  { id: 4, price: 300000, isChecked: false },
  { id: 5, price: 400000, isChecked: false },
  { id: 6, price: 500000, isChecked: false },
]
