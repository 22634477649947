import React, { useCallback, useEffect } from 'react'
import { ListTypeProducts } from '../../../constants/const'
import { ITypeProduct } from '../../../types/product'
import { Button } from 'antd'
import classNames from 'classnames'
import { AppHelper } from '../../../utils/helper'
import { CheckOutlined } from '@ant-design/icons'

interface TypeProductProps {
  handleType: (price: number) => void
  shouldReset: boolean
  onResetComplete?: () => void
}
export default function TypeProduct(props: TypeProductProps) {
  const { handleType, shouldReset, onResetComplete } = props
  const [listType, setListType] =
    React.useState<ITypeProduct[]>(ListTypeProducts)

  const resetList = useCallback(() => {
    setListType(ListTypeProducts.map((item) => ({ ...item, isChecked: false })))
    onResetComplete?.()
  }, [onResetComplete])

  useEffect(() => {
    if (shouldReset) {
      resetList()
    }
  }, [shouldReset, resetList])

  const handleItem = useCallback(
    (item: ITypeProduct) => {
      handleType(item.isChecked ? 0 : item.price)
      setListType((prevList) =>
        prevList.map((val) => ({
          ...val,
          isChecked: val.id === item.id ? !item.isChecked : false,
        })),
      )
    },
    [handleType],
  )

  const ItemType: React.FC<{ item: ITypeProduct }> = ({ item }) => (
    <Button
      className={classNames({
        'border rounded-lg relative cursor-pointer shadow-xl flex flex-col':
          true,
        'text-[#4096ff] border-[#4096ff]': item.isChecked,
      })}
      style={{ height: '80px' }}
      onClick={() => handleItem(item)}
    >
      <p className="font-semibold">{item.label}</p>
      <span className="font-medium">
        {AppHelper.fomartCurrencyVND(item.price)}
      </span>
      {item.isChecked && (
        <div className="absolute right-1 top-1">
          <CheckOutlined style={{ color: '#4096ff', fontSize: '18px' }} />
        </div>
      )}
    </Button>
  )

  const RenderItemTypes: React.FC<{ data: ITypeProduct[] }> = ({ data }) => {
    return (
      <div className="flex-auto flex gap-2">
        {data && data.map((val) => <ItemType key={val.id} item={val} />)}
      </div>
    )
  }
  return (
    <div className="flex mt-5">
      <h1 className="flex-none w-[200px]">Loại Hàng:</h1>
      {listType ? <RenderItemTypes data={listType} /> : null}
    </div>
  )
}
