import * as React from 'react'
import { InputNumericFormat } from './InputNumericFormat'
import { NumberFormatValues } from 'react-number-format'

export interface IPromotionPriceProps {
  price: number
  onChangeValue: (value: number) => void
}

export default function PromotionPrice(props: IPromotionPriceProps) {
  return (
    <div className="flex mt-5">
      <h1 className="flex-none w-[200px]">Giá khuyến mãi:</h1>
      <div className="flex-auto">
        <InputNumericFormat
          value={props.price}
          onValueChange={(values: NumberFormatValues) => {
            props.onChangeValue(values?.floatValue as number)
          }}
        />
      </div>
    </div>
  )
}
