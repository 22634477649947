import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as React from 'react';

export interface IShortDescriptionProps {
    shortDescription:string
    onChangeValue:(value:any)=>void
}

export default function ShortDescription (props: IShortDescriptionProps) {
  return (
    <div className="flex mt-5">
    <h1 className="flex-none w-[200px]">Mô tả ngắn sản phẩm:</h1>
    <div className="flex-auto">
    <CKEditor
            editor={ClassicEditor}
            data={props.shortDescription}
            onChange={(event, editor) =>
                props.onChangeValue(editor.getData())
            }
          />
    </div>
  </div>
  );
}
