import {
  Avatar,
  Button,
  Card,
  Col,
  Drawer,
  message,
  RadioChangeEvent,
  Row,
  Space,
  Spin,
} from 'antd'
import * as React from 'react'
import { APP_URL, PriceList } from '../../../constants/const'
import { IPrice, ProductAttribute } from '../../../types/product'
import { ReloadOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons'
import { ProductsHttp } from '../../../services/products.service'
import type { CheckboxProps } from 'antd'
import _orderBy from 'lodash/orderBy'
import { useForm } from 'react-hook-form'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import PromotionPrice from '../components/PromotionPrice'
import StockStatus from '../components/StockStatus'
import SizeAttributes from '../components/SizeAttributes'
import Description from '../components/Description'
import ShortDescription from '../components/ShortDescription'
import ModalUpload from '../components/ModalUpload'
import InputNameProduct from '../components/InputNameProduct'
import TypeProduct from '../components/TypeProduct'
import PriceExtra from '../components/PriceExtra'
import Price from '../components/Price'
import ProductCategories from '../components/ProductCategories'
import { WordPressHttp } from '../../../services/wp.service'
import { selectorProducts } from '../../../store/selectors/product.selector'
import { useStore } from '../../../store'
const { Meta } = Card

interface ProductData {
  name: string
  type: string
  description: string
  short_description: string
  categories?: { id: number }[]
  images?: { src: string }[]
  attributes: {
    id?: number
    name?: string
    position: number
    visible: boolean
    variation: boolean
    options: string[]
  }[]
  default_attributes: {
    id?: number
    name?: string
    option: string
  }[]
}

type StockStatus = 'instock' | 'outofstock' | 'onbackorder'
interface InputValue {
  name: string
  price: number
  priceExtra: number
  stockStatus: StockStatus
}

interface SelectCategories {
  id: number
}

const productsHttp = new ProductsHttp()

interface ProductProps {
  onClose: () => void
  open: boolean
}
const wordPressHttp = new WordPressHttp()
export default function CreateProduct(props: ProductProps) {
  const { open, onClose } = props
  const [attributeTerms, setAttributeTerms] = React.useState<
    ProductAttribute[]
  >([])
  const [categories, setCategories] = React.useState<any[]>([])
  const [price, setPrice] = React.useState<number>(0)
  const [priceExtra, setPriceExtra] = React.useState<number>(0)
  const [inputValues, setInputValues] = React.useState<InputValue>({
    name: '',
    price: 0,
    priceExtra: 0,
    stockStatus: 'instock',
  })
  const [shortDescription, setShortDescription] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('')
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false)
  const [nameProduct, setNameProduct] = React.useState<string>('')
  const [promotionPrice, setPromotionPrice] = React.useState<number>(0)

  const [selectIdImages, setSelectIdImages] = React.useState<number[]>([])
  const [urlImg, setUrlImg] = React.useState<string>(
    'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
  )
  const {
    formState: { errors },
  } = useForm<ProductData>({
    defaultValues: {
      type: 'variable',
      categories: [{ id: 0 }],
      images: [{ src: '' }],
      attributes: [
        { position: 0, visible: false, variation: false, options: [''] },
      ],
      default_attributes: [{ option: '' }],
    },
  })

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [shouldReset, setShouldReset] = React.useState<boolean>(false)
  const { products, addProduct } = useStore(selectorProducts)

  React.useEffect(() => {
    const getDetailAttributes = async () => {
      try {
        const response = await productsHttp.getDetailAttributes(2)
        const newResponse = response.data.map((item) => ({
          ...item,
          isChecked: false,
        }))
        setAttributeTerms(newResponse)
      } catch (error) {
        console.log('error', error)
      }
    }
    getDetailAttributes()
    getCategories()
  }, [])

  const getCategories = async () => {
    try {
      const response = await productsHttp.getCategory()
      const data = response.data
      const newData = data.map((item: any) => ({ ...item, isChecked: false }))
      setCategories(newData)
    } catch (error) {
      setCategories([])
    }
  }

  const onChangeStockStatus = (e: RadioChangeEvent) => {
    setInputValues((prev) => ({ ...prev, stockStatus: e.target.value }))
  }

  const handleType = (price: number) => {
    setPrice(price)
  }

  const handlePriceExtra = (price: number, action: 'incr' | 'desc') => {
    if (action === 'incr') {
      setPriceExtra((prev) => prev + price)
    } else {
      setPriceExtra((prev) => prev - price)
    }
  }
  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    const allChecked = attributeTerms.every((attr) => attr.isChecked)
    setAttributeTerms(
      attributeTerms.map((attr) => ({ ...attr, isChecked: !allChecked })),
    )
  }
  const onCheckbox = (e: CheckboxChangeEvent, val: ProductAttribute) => {
    setAttributeTerms(
      attributeTerms.map((item) =>
        item.id === val.id
          ? { ...item, isChecked: e.target.checked }
          : { ...item },
      ),
    )
  }

  const allChecked =
    attributeTerms.length > 0 && attributeTerms.every((attr) => attr.isChecked)
  const onSubmit = async () => {
    setIsLoading(true)
    const filterCategories = categories.filter((item) => item.isChecked)
    const filterAttributeTerms = attributeTerms.filter((item) => item.isChecked)
    const request: any = {
      name: nameProduct,
      type: 'variable',
      regular_price: String(price + priceExtra),
      description: description,
      short_description: shortDescription,
      categories: filterCategories.map((item) => ({
        id: Number(item.id),
      })),
      status: 'publish',
      sale_price: String(promotionPrice),
      stock_status: inputValues.stockStatus,
      attributes: [
        {
          id: 2,
          name: 'Size',
          position: 0,
          slug: 'pa_size',
          variation: true,
          visible: true,
          options: filterAttributeTerms.map((item) => item.name),
        },
      ],
      images: selectIdImages.map((id) => ({ id })),
    }
    const variations = attributeTerms.map((item) => ({
      regular_price: String(price + priceExtra),
      attributes: [
        {
          name: 'Size',
          option: item.name,
        },
      ],
    }))
    const response = await productsHttp.createProduct(request)
    if (response.status !== 201) {
      setIsLoading(false)
      message.error(`Thêm sản phẩm thất bại! ${response.data?.message}`)
      return
    }
    const productId = response.data.id
    const variationPromises = await variations.map((variation) =>
      productsHttp.generateVariationProduct(productId, variation),
    )
    message.success(`Thêm sản phẩm thành công!`)
    addProduct(response.data)
    setIsLoading(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameProduct(e.target.value)
  }

  const handleImg = async (ids: number[]) => {
    setSelectIdImages(ids)
    const response = await wordPressHttp.getMediaById(ids[0])
    if (response && response.status === 200) {
      setUrlImg(response.data.source_url)
      return
    }
    setUrlImg(
      'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
    )
  }

  const clearForm = () => {
    setNameProduct('')
    setPrice(0)
    setPriceExtra(0)
    setPromotionPrice(0)
    setAttributeTerms(
      attributeTerms.map((item) => ({ ...item, isChecked: false })),
    )
    setSelectIdImages([])
    setDescription('')
    setCategories(categories.map((item) => ({ ...item, isChecked: false })))
    setShortDescription('')
    setShouldReset(true)
  }

  const handleCategories = (item: any) => {
    if (item) {
      const newCategories = [...categories].map((val) => {
        if (val.id === item.id) {
          return { ...val, isChecked: !item.isChecked }
        }
        return { ...val }
      })
      setCategories(newCategories)
    }
  }

  const onChangePrice = (values: number) => {
    setPriceExtra(0)
    setPrice(values as number)
  }
  return (
    <Drawer
      title="Create a new product"
      width={'100%'}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button danger onClick={onClose}>
            Cancel
          </Button>
          <Button type="dashed" onClick={() => clearForm()}>
            Clear Form <ReloadOutlined />
          </Button>
          <Button onClick={onSubmit} type="primary">
            Submit <SendOutlined />
          </Button>
        </Space>
      }
    >
      <Row gutter={20}>
        <Col span={18}>
          <InputNameProduct value={nameProduct} onChange={onChangeName} />
          <TypeProduct
            handleType={handleType}
            shouldReset={shouldReset}
            onResetComplete={() => {
              setShouldReset(false)
            }}
          />
          <PriceExtra
            handlePriceExtra={handlePriceExtra}
            shouldReset={shouldReset}
            onResetComplete={() => {
              setShouldReset(false)
            }}
          />
          <Price price={price + priceExtra} onChangeValue={onChangePrice} />
          <PromotionPrice
            price={promotionPrice}
            onChangeValue={(price) => {
              setPromotionPrice(price)
            }}
          />
          <StockStatus onChangeValue={onChangeStockStatus} />
          <SizeAttributes
            onChangeAll={onCheckAllChange}
            onChangeValue={onCheckbox}
            checkAll={allChecked}
            attributeTerms={attributeTerms}
          />
          <ProductCategories
            categories={categories}
            handleCategories={handleCategories}
          />
          <Description
            description={description}
            onChangeValue={(data) => {
              setDescription(data)
            }}
          />
          <ShortDescription
            shortDescription={shortDescription}
            onChangeValue={(data) => {
              setShortDescription(data)
            }}
          />
        </Col>
        <Col span={6}>
          <Card
            cover={<img alt="Img Default" src={urlImg} />}
            actions={[
              <button onClick={handleOpenModal} key="upload">
                <UploadOutlined />
              </button>,
            ]}
          >
            <Meta
              avatar={<Avatar src="./logo.png" />}
              title="Ảnh sản phẩm"
              description="Nhấn vào để sửa hoặc cập nhật"
            />
          </Card>
        </Col>
        <ModalUpload
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          handleSelectImg={handleImg}
        />
      </Row>
      <Spin spinning={isLoading} fullscreen />
    </Drawer>
  )
}
