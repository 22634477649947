import { Checkbox, CheckboxProps } from 'antd'
import * as React from 'react'
import { ProductsHttp } from '../../../services/products.service'
import { ProductAttribute } from '../../../types/product'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

export interface ISizeAttributesProps {
  onChangeAll: (e: CheckboxChangeEvent) => void
  onChangeValue: (e: CheckboxChangeEvent, item: ProductAttribute) => void
  checkAll: boolean
  attributeTerms: ProductAttribute[]
}

export default function SizeAttributes(props: ISizeAttributesProps) {
  return (
    <div className="flex mt-5">
      <h1 className="flex-none w-[200px]">Size:</h1>
      <div className="flex-auto">
        <Checkbox onChange={props.onChangeAll} checked={props.checkAll}>
          Check all
        </Checkbox>
        {props.attributeTerms &&
          props.attributeTerms.map((item) => (
            <Checkbox
              key={item.id}
              value={item.id}
              onChange={(e) => props.onChangeValue(e, item)}
              checked={item.isChecked}
            >
              {item.name}
            </Checkbox>
          ))}
      </div>
    </div>
  )
}
