import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import * as React from 'react'

export interface IDescriptionProps {
  onChangeValue: (value: any) => void
  description: string
}

export default function Description(props: IDescriptionProps) {
  return (
    <div className="flex mt-5">
      <h1 className="flex-none w-[200px]">Mô tả sản phẩm:</h1>
      <div className="flex-auto">
        <CKEditor
          editor={ClassicEditor}
          data={props.description}
          onChange={(event, editor) => props.onChangeValue(editor.getData())}
        />
      </div>
    </div>
  )
}
