import React from 'react'
import { Product } from '../../../types/product'
import { Button, Card, Col, Drawer, Row, Space, Spin } from 'antd'
import { ReloadOutlined, SendOutlined } from '@ant-design/icons'
import InputNameProduct from '../components/InputNameProduct'
import TypeProduct from '../components/TypeProduct'
import PriceExtra from '../components/PriceExtra'
import Price from '../components/Price'
import PromotionPrice from '../components/PromotionPrice'

interface EditProductPros {
  item: Product
  onClose: () => void
  open: boolean
}
export default function EditProduct(props: EditProductPros) {
  const { item, onClose, open } = props
  console.log('🚀 ~ EditProduct ~ item:', item)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [value, setValue] = React.useState<string>(item.name ?? '')
  const clearForm = () => {
    // Clear form
  }

  const onSubmit = () => {
    // Submit form
  }

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }
  return (
    <Drawer
      title="Update Product"
      width={'100%'}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button danger onClick={onClose}>
            Cancel
          </Button>
          <Button type="dashed" onClick={() => clearForm()}>
            Clear Form <ReloadOutlined />
          </Button>
          <Button onClick={onSubmit} type="primary">
            Submit <SendOutlined />
          </Button>
        </Space>
      }
    >
      <Row gutter={20}>
        <Col span={18}>
          <InputNameProduct value={value} onChange={onChangeValue} />
          {/* <TypeProduct
            handleType={handleType}
            shouldReset={shouldReset}
            onResetComplete={() => {
              setShouldReset(false)
            }}
          /> */}
          {/* <PriceExtra
            handlePriceExtra={handlePriceExtra}
            shouldReset={shouldReset}
            onResetComplete={() => {
              setShouldReset(false)
            }}
          /> */}
          {/* <Price price={price + priceExtra} onChangeValue={onChangePrice} /> */}
          {/* <PromotionPrice
            price={promotionPrice}
            onChangeValue={(price) => {
              setPromotionPrice(price)
            }}
          /> */}
          {/* <StockStatus onChangeValue={onChangeStockStatus} /> */}
          {/* <SizeAttributes
            onChangeAll={onCheckAllChange}
            onChangeValue={onCheckbox}
            checkAll={allChecked}
            attributeTerms={attributeTerms}
          /> */}
          {/* <ProductCategories
            categories={categories}
            handleCategories={handleCategories}
          /> */}
          {/* <Description
            description={description}
            onChangeValue={(data) => {
              setDescription(data)
            }}
          /> */}
          {/* <ShortDescription
            shortDescription={shortDescription}
            onChangeValue={(data) => {
              setShortDescription(data)
            }}
          /> */}
        </Col>
        <Col span={6}>
          {/* <Card
            cover={<img alt="Img Default" src={urlImg} />}
            actions={[
              <button onClick={handleOpenModal} key="upload">
                <UploadOutlined />
              </button>,
            ]}
          >
            <Meta
              avatar={<Avatar src="./logo.png" />}
              title="Ảnh sản phẩm"
              description="Nhấn vào để sửa hoặc cập nhật"
            />
          </Card> */}
        </Col>
        {/* <ModalUpload
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          handleSelectImg={handleImg}
        /> */}
      </Row>
      <Spin spinning={isLoading} fullscreen />
    </Drawer>
  )
}
