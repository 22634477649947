import { notification } from 'antd'
import type { NotificationArgsProps } from 'antd'

type NotificationType = 'success' | 'info' | 'warning' | 'error'
interface NotificationConfig extends NotificationArgsProps {
  type: NotificationType
  message: string
  description: string
}

export const useNotification = () => {
  const [api, contextHolder] = notification.useNotification()

  const openNotification = ({
    type,
    message,
    description,
    placement = 'top',
    ...rest
  }: NotificationConfig) => {
    api[type]({
      message,
      description,
      placement,
      ...rest,
    })
  }

  const openNotificationSuccess = ({
    message,
    description,
    placement = 'top',
    ...rest
  }: Omit<NotificationConfig, 'type'>) =>
    openNotification({
      type: 'success',
      message,
      description,
      placement,
      ...rest,
    })

  const openNotificationError = ({
    message,
    description,
    placement = 'top',
    ...rest
  }: Omit<NotificationConfig, 'type'>) =>
    openNotification({
      type: 'error',
      message,
      description,
      placement,
      ...rest,
    })

  const openNotificationWarning = ({
    message,
    description,
    placement = 'top',
    ...rest
  }: Omit<NotificationConfig, 'type'>) =>
    openNotification({
      type: 'warning',
      message,
      description,
      placement,
      ...rest,
    })

  const openNofificationInfo = ({
    message,
    description,
    placement = 'top',
    ...rest
  }: Omit<NotificationConfig, 'type'>) =>
    openNotification({
      type: 'info',
      message,
      description,
      placement,
      ...rest,
    })

  return {
    openNotification,
    openNofificationInfo,
    openNotificationSuccess,
    openNotificationError,
    openNotificationWarning,
    contextHolder,
  }
}
