import React from 'react'
import { IPrice } from '../../../types/product'
import { PriceList } from '../../../constants/const'
import { AppHelper } from '../../../utils/helper'

interface PriceExtraProps {
  handlePriceExtra: (price: number, action: 'incr' | 'desc') => void
  shouldReset: boolean
  onResetComplete?: () => void
}
export default function PriceExtra(pros: PriceExtraProps) {
  const { handlePriceExtra, shouldReset, onResetComplete } = pros
  const [listPrices, setListPrices] = React.useState<IPrice[]>(PriceList)
  return (
    <div className="flex mt-5">
      <h1 className="flex-none w-[200px]">Thêm giá:</h1>
      <div className="flex-auto flex gap-2">
        {listPrices.map((item) => (
          <div
            key={item.id}
            className="border h-[40px] rounded-lg relative cursor-pointer shadow-xl flex items-center gap-2 active:text-[#4096ff] active:border-[#4096ff]"
          >
            <button
              className="flex-1 px-4 border-l font-bold"
              onClick={() => handlePriceExtra(item.price, 'desc')}
            >
              -
            </button>
            <p className="font-semibold flex-1">
              {AppHelper.fomartCurrencyVND(item.price)}
            </p>
            <button
              className="flex-1 px-4 border-r font-bold"
              onClick={() => handlePriceExtra(item.price, 'incr')}
            >
              +
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
