import * as React from 'react'
import { ProductsHttp } from '../../services/products.service'
import { ProductAttribute } from '../../types/product'
import { Collapse, CollapseProps } from 'antd'
import _orderBy from 'lodash/orderBy'
import DynamicPanel from './DynamicPanel'

const productsHttp = new ProductsHttp()
export default function Attributes() {
  const [fieldsAttributes, setFieldsAttributes] = React.useState<
    ProductAttribute[]
  >([])
  const [activeKey, setActiveKey] = React.useState<string | string[]>([])

  React.useEffect(() => {
    getListAttributes()
  }, [])

  const getListAttributes = async () => {
    try {
      const response = await productsHttp.getAttributes()
      setFieldsAttributes(response.data)
    } catch (error) {
      setFieldsAttributes([])
    }
  }

  const onChangeCollapse = (key: string | string[]) => {
    setActiveKey(key)
  }
  const convertToCollapseItems = (
    customItems: ProductAttribute[],
  ): CollapseProps['items'] => {
    return customItems.map((item) => ({
      key: item.id,
      label: item.name,
      children: <DynamicPanel itemKey={item.id} />,
    }))
  }

  return (
    <div className="w-1/2">
      <Collapse
        items={convertToCollapseItems(fieldsAttributes)}
        onChange={onChangeCollapse}
        activeKey={activeKey}
      />
    </div>
  )
}
