import { Input } from 'antd'
import * as React from 'react'
import { NumberFormatBase, NumberFormatBaseProps } from 'react-number-format'

export function InputNumericFormat(props: NumberFormatBaseProps): JSX.Element {
  const format = (numStr: string): string => {
    if (numStr === '') return ''
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
      maximumFractionDigits: 0,
    }).format(Number(numStr))
  }

  return (
    <NumberFormatBase
      {...props}
      format={format}
      className="input-numberic-format"
    />
  )
}
