import { AxiosInstance } from 'axios'
import { API, APP_URL } from '../constants/const'
import { HttpWPRequest } from '../config/api_wp'

export class WordPressHttp {
  request: AxiosInstance
  constructor(endPoint = APP_URL.END_POINT) {
    this.request = new HttpWPRequest(endPoint).axiosInstance
  }
  public getMedia(): Promise<any> {
    return this.request.get(`${API.MEDIA}?per_page=100`)
  }

  public updateMedia(data: any): Promise<any> {
    return this.request.post(API.MEDIA, data)
  }

  public getMediaById(id: number): Promise<any> {
    return this.request.get(`${API.MEDIA}/${id}`)
  }
}
