import { StateCreator } from 'zustand'
import { Product } from '../../types/product'
import { ProductSlice, ProductState } from '../types/product.type'

export const productSlice: StateCreator<ProductSlice, [], [], ProductState> = (
  set,
  get,
) => ({
  products: [],
  setProducts: (products: Product[]) => set({ products }),
  addProduct: (product: Product) =>
    set((state) => ({ products: [product, ...state.products] })),
  removeProduct: (id: number) =>
    set((state) => ({ products: state.products.filter((p) => p.id !== id) })),
  updateProduct: (product: Product) =>
    set((state) => ({
      products: state.products.map((p) => (p.id === product.id ? product : p)),
    })),
  getProduct: (id: number) => get().products.find((p) => p.id === id),
  getProducts: () => get().products,
})

export const createProductSlice: StateCreator<
  ProductSlice,
  [],
  [],
  ProductState
> = (...args) => ({ ...productSlice(...args) })
