import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { StoreState } from './types'
import { createProductSlice } from './slices/productSlice'
export const useStore = create<StoreState>()(
  devtools(
    persist(
      (set, get, api) => ({
        ...createProductSlice(set, get, api),
      }),
      {
        name: 'store-storage',
        partialize: (state) => ({
          ...state,
          // isLoyalty: undefined,
        }),
      },
    ),
  ),
)
