import React from 'react'
import { InputNumericFormat } from './InputNumericFormat'
import { NumberFormatValues } from 'react-number-format'

export default function Price(props: {
  price: number
  onChangeValue: (price: number) => void
}) {
  const { price, onChangeValue } = props
  return (
    <div className="flex mt-5">
      <h1 className="flex-none w-[200px]">Giá:</h1>
      <div className="flex-auto">
        <InputNumericFormat
          value={price}
          onValueChange={(values: NumberFormatValues) => {
            onChangeValue(values?.floatValue as number)
          }}
        />
      </div>
    </div>
  )
}
