
  import dayjs, { Dayjs } from 'dayjs';
  import 'dayjs/locale/vi';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';
import { FORMAT } from '../constants/const';
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const tz = 'Asia/Ho_Chi_Minh';
  

  export class AppHelper {
   
    static formatDate(time: string | Dayjs, format: string): string {
      return dayjs.tz(time, tz).format(format);
    }
    static formatSessionEndTime(
      time: string,
      duration: number,
      format: string
    ): string {
      const newTime = dayjs.tz(time).add(duration, 'm');
      return newTime.format(format);
    }
    static fomartCurrencyVND(price: number) {
      const vnd = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      });
      return vnd.format(price);
    }
  
  
    

    static compareDate(date: string): boolean {
      const today = dayjs.tz();
      const showTime = dayjs.tz(date, tz);
      if (today.isSame(showTime, 'day')) return true;
      return false;
    }
    static diffMinutes(time: string) {
      const now = dayjs.tz();
      const showTime = dayjs.tz(time, tz);
      const diffDuration = showTime.diff(now, 'minutes');
      return diffDuration;
    }
    static currentDate(): string {
      const now = dayjs.tz();
      return this.formatDate(now, FORMAT.DATE_FULL);
    }
  
    static diffSessionEndTime(time: string, duration: number) {
      const now = dayjs.tz();
      const showTime = dayjs.tz(time, tz).add(duration, 'm');
      const diffDuration = showTime.diff(now, 'minutes');
      return diffDuration;
    }
    static formatMinutesToHoursAndMinutes = (durationInMinutes: number) => {
      const hours = Math.floor(durationInMinutes / 60);
      const remainingMinutes = durationInMinutes % 60;
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}`;
    };
  }
  