import { Radio, RadioChangeEvent } from 'antd'
import * as React from 'react'

export interface IStockStatusProps {
  onChangeValue: (value: RadioChangeEvent) => void
}

export default function StockStatus(props: IStockStatusProps) {
  const [value, setValue] = React.useState<string>('instock')

  const onChecked = (e: RadioChangeEvent) => {
    setValue(e.target.value)
    props.onChangeValue(e)
  }
  return (
    <div className="flex mt-5">
      <h1 className="flex-none w-[200px]">Trạng thái:</h1>
      <div className="flex-auto">
        <Radio.Group onChange={onChecked} value={value}>
          <Radio value="instock">Còn hàng</Radio>
          <Radio value="outofstock">Hết hàng</Radio>
          <Radio value="onbackorder">Chờ hàng</Radio>
        </Radio.Group>
      </div>
    </div>
  )
}
