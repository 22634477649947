import {
  CheckCircleFilled,
  CloseOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import * as React from 'react'
import {
  Button,
  Card,
  Checkbox,
  Drawer,
  message,
  Spin,
  Upload,
  type GetProp,
  type UploadFile,
  type UploadProps,
} from 'antd'
import { GlobalHttp } from '../../../services/global.service'
import { RcFile } from 'antd/es/upload'
import { WordPressHttp } from '../../../services/wp.service'
import { APP_URL } from '../../../constants/const'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]
interface Image {
  id: number
  src: string
  name: string
}
export interface IModalUploadProps {
  open: boolean
  onClose: () => void
  handleSelectImg: (id: number[]) => void
}

const URL = APP_URL.END_POINT
const wordPressHttp = new WordPressHttp()
export default function ModalUpload(props: IModalUploadProps) {
  const { open, onClose, handleSelectImg } = props
  const [fileList, setFileList] = React.useState<UploadFile[]>([])
  const [mediaLibrary, setMediaLibrary] = React.useState<Image[]>([])
  const [selectedImages, setSelectedImages] = React.useState<number[]>([])
  const [loading, setLoading] = React.useState(false)
  const [token, setToken] = React.useState<string>('')

  React.useEffect(() => {
    const storedToken = localStorage.getItem('token')
    if (storedToken) {
      setToken(storedToken)
    }
  }, [])

  React.useEffect(() => {
    const fetchData = async () => {
      const dataMedia = await wordPressHttp.getMedia()
      if (dataMedia && dataMedia.status !== 200) {
        setFileList([])
        return
      }
      const newData = dataMedia.data.map((item: any) => {
        return {
          uid: item.id,
          name: item.media_details.file,
          status: 'done',
          url: item.source_url,
        }
      })
      setMediaLibrary(
        dataMedia.data.map((item: any) => ({
          id: item.id,
          src: item.source_url,
          name: item.title.rendered,
        })),
      )
      if (newData.length > 0) {
        setFileList(newData)
        return
      }
      setFileList([])
    }
    fetchData()
  }, [])

  const handleImageSelect = (id: number) => {
    setSelectedImages((prev) =>
      prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id],
    )
  }

  const handleUpload: UploadProps['onChange'] = async (info) => {
    setLoading(true)
    const { status } = info.file

    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`)
      const newImage: Image = {
        id: info.file.response.id,
        src: info.file.response.source_url,
        name: info.file.name,
      }
      setMediaLibrary((prevLibrary) => [newImage, ...prevLibrary])
      setLoading(false)
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
      setLoading(false)
    }
  }

  const uploadProps = {
    name: 'file',
    action: `${URL}/wp-json/wp/v2/media`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onChange: handleUpload,
    showUploadList: false,
  }
  const handleAddToLibrary = () => {
    handleSelectImg(selectedImages)
    onClose()
    setSelectedImages([])
  }

  const handleCloseModal = () => {
    onClose()
    setSelectedImages([])
  }
  return (
    <Drawer
      title="Upload Images"
      placement="right"
      closable={true}
      onClose={handleCloseModal}
      open={open}
      width={`90%`}
      closeIcon={<CloseOutlined />}
      extra={
        <Upload {...uploadProps} multiple>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      }
      footer={
        <div className="flex justify-end">
          <Button type="primary" onClick={handleAddToLibrary}>
            Thêm vào thư viện
          </Button>
        </div>
      }
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {mediaLibrary.map((image) => (
          <Card
            key={image.id}
            hoverable
            style={{
              width: 240,
              margin: 16,
              border: selectedImages.includes(image.id)
                ? '2px solid #1890ff'
                : '1px solid #d9d9d9',
              position: 'relative',
            }}
            cover={
              <img
                alt={image.name}
                src={image.src}
                style={{ height: 200, objectFit: 'cover' }}
              />
            }
            onClick={() => handleImageSelect(image.id)}
          >
            <Card.Meta title={image.name} />
            {selectedImages.includes(image.id) && (
              <CheckCircleFilled
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  fontSize: '24px',
                  color: '#1890ff',
                }}
              />
            )}
          </Card>
        ))}
      </div>
      <Spin spinning={loading} fullscreen />
    </Drawer>
  )
}
