import { CheckOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import classNames from 'classnames'
import React from 'react'

interface ProductCategoriesProps {
  categories: any[]
  handleCategories: (category: any) => void
}
export default function ProductCategories(props: ProductCategoriesProps) {
  const { categories, handleCategories } = props
  return (
    <div className="flex mt-5">
      <h1 className="flex-none w-[200px]">Danh mục sản phẩm:</h1>
      <div className="flex-auto flex gap-2">
        {categories.map((item) => {
          return (
            <Button
              key={item.id}
              className={classNames({
                'border rounded-lg relative cursor-pointer shadow-xl flex flex-col':
                  true,
                'text-[#4096ff] border-[#4096ff]': item.isChecked,
              })}
              style={{ height: '40px' }}
              onClick={() => handleCategories(item)}
            >
              <p className="font-semibold">{item.name}</p>

              {item.isChecked ? (
                <div className="absolute right-[2px] top-0">
                  <CheckOutlined
                    style={{ color: '#4096ff', fontSize: '14px' }}
                  />
                </div>
              ) : null}
            </Button>
          )
        })}
      </div>
    </div>
  )
}
