import { Button, Flex, Table, TableColumnsType, Tag, Typography } from 'antd'
import * as React from 'react'
import { ProductsHttp } from '../../services/products.service'
import { hasError } from '../../config/axios'
import { Product } from '../../types/product'
import { AppHelper } from '../../utils/helper'
import CreateProduct from './CreateProduct'
import ProductTable from './ProductTable'
const { Text } = Typography

export default function Products() {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <div className="flex justify-end overflow-hidden">
        <Button size="large" type="primary" onClick={() => setOpen(true)}>
          Thêm Sản Phẩm
        </Button>
      </div>
      <div style={{ height: 'calc(100vh - 100px)', overflow: 'hidden' }}>
        <ProductTable />
      </div>
      <CreateProduct open={open} onClose={() => setOpen(false)} />
    </>
  )
}
