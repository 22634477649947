import { Input } from 'antd'
import React from 'react'

interface InputNameProductProps {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
export default function InputNameProduct(props: InputNameProductProps) {
  const { value, onChange } = props
  return (
    <div className="flex">
      <h1 className="flex-none w-[200px]">Tên sản phẩm:</h1>
      <div className="flex-auto">
        <Input
          name="name"
          placeholder="Nhập tên sản phẩm"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  )
}
